export default function romanize(num) {
  if (!+num) return false;
  var digits = String(+num).split(""),
    key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM", "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC", "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
    roman = "",
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
}

// let currentTime = new Date();
// let currentTimeMoment = this.$moment()
// console.log(currentTime);
// console.log(currentTime.getDate(), currentTimeMoment.format("D"));
// console.log(currentTime.getMonth(), currentTimeMoment.format("M"));
// console.log(currentTime.getFullYear(), currentTimeMoment.format("YYYY"));
// console.log(currentTime);
// let strRomanDate = romanize(currentTime.getMonth() + 1) + " . " + romanize(currentTime.getDate()) + " . " + romanize(currentTime.getFullYear()) + 1;
// // let strRomanDateMoment = romanize(currentTimeMoment.format("M") + 1) + " . " + romanize(currentTimeMoment.format("D")) + " . " + romanize(currentTimeMoment.format("YYYY")) + 1;

// console.log(strRomanDate);
// // console.log(strRomanDateMoment);