import Vue from "vue";
import Router from "vue-router";
import stores from "@/store/store";
import { setToken } from "../store/utils/auth.js";

// Containers
const TheContainer = () => import("@/containers/TheContainer");
const TheContainerDinkes = () => import("@/containers/TheContainerDinkes");

// Views
const Dashboard = () => import("@/views/Dashboard");
const DashboardPuskesmas = () => import("@/views/dashboard_puskemsas/dashboard_puskemsas");
// Views - Pages
const Login = () => import("@/views/pages/Login");
const ChangePasssword = () => import("@/views/change_password/change_password");
const Icare = () => import("@/views/icare/icare");
const SelamatDatang = () => import("@/views/pages/selamat_datang");
const Maintenance = () => import("@/views/pages/sedang_mt");
//Master
const Alergi = () => import("@/views/master/ms_alergi/ms_alergi");
const Asuransi = () => import("@/views/master/ms_asuransi/ms_asuransi");
Vue.component("Asuransi", Asuransi);
const ListDiagnosa = () => import("@/views/master/ms_diagnosa/ms_diagnosa");
const DiagnosaGizi = () => import("@/views/master/ms_diagnosa_gizi/ms_diagnosa_gizi");
const Imunisasi = () => import("@/views/master/ms_imunisasi/ms_imunisasi");
const Diet = () => import("@/views/master/ms_diet/ms_diet");
const Barang = () => import("@/views/master/ms_barang/ms_barang");
const Etnis = () => import("@/views/master/ms_etnis/ms_etnis");
const GolonganBarang = () => import("@/views/master/ms_golongan_barang/ms_golongan_barang");
const GolonganDarah = () => import("@/views/master/ms_golongan_darah/ms_golongan_darah");
const Gudang = () => import("@/views/master/ms_gudang/ms_gudang");
const Jadwal = () => import("@/views/master/ms_jadwal/ms_jadwal");
const JenisAlergi = () => import("@/views/master/ms_jenis_alergi/ms_jenis_alergi");
const JenisBarang = () => import("@/views/master/ms_jenis_barang/ms_jenis_barang");
const JenisLayanan = () => import("@/views/master/ms_jenis_layanan/ms_jenis_layanan");
const JenisTenagaMedis = () => import("@/views/master/ms_jenis_tenaga_medis/ms_jenis_tenaga_medis");
const Kelas = () => import("@/views/master/ms_kelas/ms_kelas");
const KelasTerapi = () => import("@/views/master/ms_kelas_terapi/ms_kelas_terapi");
const KelompokPangan = () => import("@/views/master/ms_kelompok_pangan/ms_kelompok_pangan");
const KomponenTarif = () => import("@/views/master/ms_komponen_tarif/ms_komponen_tarif");
const KomposisiBarang = () => import("@/views/master/ms_komposisi_barang/ms_komposisi_barang");
const Kualifikasi = () => import("@/views/master/ms_kualifikasi/ms_kualifikasi");
const Layanan = () => import("@/views/master/ms_layanan/ms_layanan");
const Loket = () => import("@/views/master/ms_loket/ms_loket");
const Pendidikan = () => import("@/views/master/ms_pendidikan/ms_pendidikan");
const Poli = () => import("@/views/master/ms_poli/ms_poli");
const Produsen = () => import("@/views/master/ms_produsen/ms_produsen");
const SatuanBarang = () => import("@/views/master/ms_satuan_barang/ms_satuan_barang");
const Specialist = () => import("@/views/master/ms_specialist/ms_specialist");
const StatusPulang = () => import("@/views/master/ms_status_pulang/ms_status_pulang");
const SumberDana = () => import("@/views/master/ms_sumber_dana/ms_sumber_dana");
const Supplier = () => import("@/views/master/ms_supplier/ms_supplier");
const TenagaMedis = () => import("@/views/master/tenaga_medis/tenaga_medis");
const MCU = () => import("@/views/master/ms_mcu/ms_mcu");
const Pangan = () => import("@/views/master/ms_pangan/ms_pangan");
const SatuanLab = () => import("@/views/master/ms_satuan_lab/ms_satuan_lab");
const Tindakan = () => import("@/views/master/ms_tindakan/ms_tindakan");
const TipePangan = () => import("@/views/master/ms_tipe_pangan/ms_tipe_pangan");
const SampelLab = () => import("@/views/master/ms_sample_lab/ms_sample_lab");

const Puskesmas = () => import("@/views/master/puskesmas/puskesmas");
const User = () => import("@/views/master/user/user");

const MS_KAMAR = () => import("@/views/master_bed/ms_kamar/ms_kamar");
const MS_RUANG = () => import("@/views/master_bed/ms_ruang/ms_ruang");
const MS_BED = () => import("@/views/master_bed/ms_bed/ms_bed");
const Monitoring = () => import("@/views/master_bed/monitoring/monitoring");
const HistoryBed = () => import("@/views/master_bed/history_bed/history_bed");

// ANTRIAN
const DisplayAPM = () => import("@/views/antrian/display_kios");
const DisplayAPM2 = () => import("@/views/antrian/display_kios");
const DisplayAPM3 = () => import("@/views/antrian/display_kios");
const DisplayLayarLoket = () => import("@/views/antrian/display_antrian_loket");
const DisplayLayarPoli = () => import("@/views/antrian/display_antrian_poli");
const DisplayListPoli = () => import("@/views/antrian/display_list_poly");
const AntrianLoket = () => import("@/views/antrian/list_antrian/list_antrian_loket");
const AntrianPoli = () => import("@/views/antrian/list_antrian/list_antrian_poli");
const AntrianFarmasi = () => import("@/views/antrian/list_antrian/list_antrian_farmasi");

const RegisterLamaUmum = () => import("@/views/antrian/register_lama_umum");
const RegisterLamaBPJS = () => import("@/views/antrian/register_lama_bpjs");
const RegisterPoli = () => import("@/views/antrian/register_poli");
const RegisterPoliBaru = () => import("@/views/antrian/register_poli_baru");
const RegisterPoliBPJS = () => import("@/views/antrian/register_poli_bpjs");

const RekamMedis = () => import("@/views/pasien/rekam_medis/rekam_medis");
const DetailRekamMedis = () => import("@/views/pasien/rekam_medis/detail_rekam_medis");
const DetailKunjunganByRekamMedis = () => import("@/views/pasien/rekam_medis/detail_kunjungan_by_rekam_medis");
const PasienMeninggal = () => import("@/views/pasien/pasien_meninggal/pasien_meninggal");
const InputPenyebabKematian = () => import("@/views/pasien/pasien_meninggal/input_penyebab_kematian");
const InputLaporanKematian = () => import("@/views/pasien/pasien_meninggal/input_laporan_kematian");
const KegiatanKelompok = () => import("@/views/pasien/kegiatan_kelompok/kegiatan_kelompok");

//REGISTRASI
const Diagnosa = () => import("@/views/registrasi/diagnosa/diagnosa");
const Pendaftaran = () => import("@/views/registrasi/pendaftaran/pendaftaran");
const Kunjungan = () => import("@/views/registrasi/kunjungan/kunjungan");
const PenimbanganBalita = () => import("@/views/registrasi/penimbangan_balita/penimbangan_balita");
const RujukEksternal = () => import("@/views/registrasi/kunjungan/rujuk_eksternal/rujuk_eksternal");
const RawatInap = () => import("@/views/registrasi/rawat_inap/rawat_inap");
const RawatInap1 = () => import("@/views/registrasi/rawat_inap_1/rawat_inap");

//KASIR
const Pembayaran = () => import("@/views/kasir/pembayaran/pembayaran");

//LAPORAN HARIAN
const LaporanKunjunganPasien= () => import("@/views/laporan/kunjungan_pasien/kunjungan_pasien");
const LaporanKunjunganPasienBPJS= () => import("@/views/laporan/kunjungan_pasien/kunjungan_pasien_bpjs");
const LaporanPenyakit= () => import("@/views/laporan/penyakit/penyakit");
const LaporanPemeriksaanMedis= () => import("@/views/laporan/pemeriksaan_medis/pemeriksaan_medis");
const LaporanPelayananPasien= () => import("@/views/laporan/pelayanan_pasien/pelayanan_pasien");
const LaporanPelayananResep= () => import("@/views/laporan/resep/resep");
const LaporanPelayananObat= () => import("@/views/laporan/obat/obat");
const LaporanTindakanTenagaMedis= () => import("@/views/laporan/tindakan_tenaga_medis/tindakan_tenaga_medis");
const LaporanPendapatanTidakan= () => import("@/views/laporan/pendapatan_tindakan/pendapatan_tindakan");
const LaporanRujukInternal= () => import("@/views/laporan/rujuk_internal/rujuk_internal");
const PelayananRuang= () => import("@/views/laporan/pelayanan_ruang/pelayanan_ruang");
const KinerjaPuskesmas= () => import("@/views/laporan/kinerja_puskesmas/kinerja_puskesmas");
const LaporanPemeriksaanLab= () => import("@/views/laporan/pemeriksaan_lab/pemeriksaan_lab");
const LaporanPKPR= () => import("@/views/laporan/pkpr/pkpr");
const LaporanKunjunganPTM= () => import("@/views/laporan/kunjungan_ptm/kunjungan_ptm");
const LaporanRujukEksternal= () => import("@/views/laporan/rujuk_eksternal/rujuk_eksternal");
const LaporanPendapatanKasir = () => import("@/views/laporan/pendapatan_kasir/pendapatan_kasir");

//LAPORAN BULANAN
const LaporanSp3lb1 = () => import("@/views/laporan_bulanan/sp3lb1/sp3lb1");
const LaporanUkp4 = () => import("@/views/laporan_bulanan/ukp4/ukp4");
const LaporanUkp5 = () => import("@/views/laporan_bulanan/ukp5/ukp5");
const LaporanBulananKunjunganIbuHamil = () => import("@/views/laporan_bulanan/kunjungan_ibu_hamil/kunjungan_ibu_hamil")
const LaporanBulananKohortKia = () => import("@/views/laporan_bulanan/kohort_kia/kohort_kia")
const InfeksiMenularSeksual = () => import("@/views/laporan_bulanan/infeksi_menular_seksual/infeksi_menular_seksual");
const LaporanKonselingHiv = () => import("@/views/laporan_bulanan/konseling_hiv/konseling_hiv");
const RekapitulasiMtbsMtbm = () => import("@/views/laporan_bulanan/rekapitulasi_mtbs_mtbm/rekapitulasi_mtbs_mtbm");

//GRAFIK
const PemeriksaanTenagaMedis = () => import("@/views/grafik/pemeriksaan_tenaga_medis/pemeriksaan_tenaga_medis");
const PendapatanHarian = () => import("@/views/grafik/pendapatan_harian/pendapatan_harian");
const StatistikPasien = () => import("@/views/grafik/statistik_pasien/statistik_pasien");
const GrafikTindakan = () => import("@/views/grafik/tindakan/tindakan");
const Transaksi = () => import("@/views/grafik/transaksi/transaksi");
const GrafikPembayaran = () => import("@/views/grafik/pembayaran/pembayaran");
const Penyakit = () => import("@/views/grafik/penyakit/penyakit");
const GrafikStockOpname = () => import("@/views/grafik/stock_opname/stock_opname");
const StatistikKunjungan = () => import("@/views/grafik/statistik_kunjungan/statistik_kunjungan");
const StatistikPembelian = () => import("@/views/grafik/statistik_pembelian/statistik_pembelian");
const StatistikProlanis = () => import("@/views/grafik/statistik_prolanis/statistik_prolanis");
const GrafikKunjungan = () => import("@/views/grafik/kunjungan/kunjungan");

// STOCK
const Pembelian = () => import("@/views/gudang/pembelian/pembelian");
const Stock = () => import("@/views/gudang/stock/stock");
const StockByGudanag = () => import("@/views/gudang/stock/stockByGudang");
const StockByGudangPerBarang = () => import("@/views/gudang/stock/stockByGudangPerBarang");
const StockByGudangDetail = () => import("@/views/gudang/stock/stockByGudangDetail");
const StockOpname = () => import("@/views/gudang/stock/stockOpname/listStockOpname");
const DetailStockOpname = () => import("@/views/gudang/stock/stockOpname/detailStockOpname");
const AddDetailStockOpname = () => import("@/views/gudang/stock/stockOpname/addDetail");
const Mutasi = () => import("@/views/gudang/mutasi/mutasi");
const Adjutsment = () => import("@/views/gudang/adjutsment/adjutsment");

// APOTEK
const Resep = () => import("@/views/apotek/resep/resep");
const DetailResep = () => import("@/views/apotek/detailResep/detailResep");

// LABORATORIUM
const TindakanLab = () => import("@/views/laboratorium/tindakan_lab/tindakan_lab");
const InputTindakanLab = () => import("@/views/laboratorium/tindakan_lab/input_tindakan");
const EditTindakanLab = () => import("@/views/laboratorium/tindakan_lab/edit_tindakan");
const PaketLab = () => import("@/views/laboratorium/paket_lab/paket_lab");
const InputPaketLab = () => import("@/views/laboratorium/paket_lab/input_paket");
const EditPaketLab = () => import("@/views/laboratorium/paket_lab/edit_paket");
const NilaiRujukanLab = () => import("@/views/laboratorium/nilai_rujukan_lab/nilai_rujukan_lab");
const InputNilaiRujukanLab = () => import("@/views/laboratorium/nilai_rujukan_lab/input_nilai_rujukan_lab");
const EditNilaiRujukanLab = () => import("@/views/laboratorium/nilai_rujukan_lab/edit_nilai_rujukan_lab");
const DetailNilaiRujukanLab = () => import("@/views/laboratorium/nilai_rujukan_lab/detail");
const PemeriksaanLab = () => import("@/views/laboratorium/pemeriksaan_lab/pemeriksaan_lab");
const InputPemeriksaanLab = () => import("@/views/laboratorium/pemeriksaan_lab/input_pemeriksaan_lab");
const DetailPemeriksaanLab = () => import("@/views/laboratorium/pemeriksaan_lab/detail_pemeriksaan_lab");

// PRINT
const PrintLabelPasien = () => import("@/views/cetak/cetakLabelPasien");

//CMS
const MsAkses = () => import("@/views/cms/ms_akses/ms_akses");
const RoleAkses = () => import("@/views/cms/role_akses/role_akses");
const MsRole = () => import("@/views/cms/ms_role/ms_role");

//LAPORAN HARIAN DINKES
const LaporanKunjunganPasienDinkes= () => import("@/views/dinkes/laporan/kunjungan_pasien/kunjungan_pasien");
const LaporanKunjunganPasienBPJSDinkes= () => import("@/views/dinkes/laporan/kunjungan_pasien/kunjungan_pasien_bpjs");
const LaporanPenyakitDinkes= () => import("@/views/dinkes/laporan/penyakit/penyakit");
const LaporanPemeriksaanMedisDinkes= () => import("@/views/dinkes/laporan/pemeriksaan_medis/pemeriksaan_medis");
const LaporanPelayananPasienDinkes= () => import("@/views/dinkes/laporan/pelayanan_pasien/pelayanan_pasien");
const LaporanPelayananResepDinkes= () => import("@/views/dinkes/laporan/resep/resep");
const LaporanPelayananObatDinkes= () => import("@/views/dinkes/laporan/obat/obat");
const LaporanTindakanTenagaMedisDinkes= () => import("@/views/dinkes/laporan/tindakan_tenaga_medis/tindakan_tenaga_medis");
const LaporanPendapatanTidakanDinkes= () => import("@/views/dinkes/laporan/pendapatan_tindakan/pendapatan_tindakan");
const LaporanRujukInternalDinkes= () => import("@/views/dinkes/laporan/rujuk_internal/rujuk_internal");
const PelayananRuangDinkes= () => import("@/views/dinkes/laporan/pelayanan_ruang/pelayanan_ruang");
const KinerjaPuskesmasDinkes= () => import("@/views/dinkes/laporan/kinerja_puskesmas/kinerja_puskesmas");
const LaporanPemeriksaanLabDinkes= () => import("@/views/dinkes/laporan/pemeriksaan_lab/pemeriksaan_lab");
const LaporanPKPRDinkes= () => import("@/views/dinkes/laporan/pkpr/pkpr");
const LaporanKunjunganPTMDinkes= () => import("@/views/dinkes/laporan/kunjungan_ptm/kunjungan_ptm");
const LaporanRujukEksternalDinkes= () => import("@/views/dinkes/laporan/rujuk_eksternal/rujuk_eksternal");
const LaporanPendapatanKasirDinkes = () => import("@/views/dinkes/laporan/pendapatan_kasir/pendapatan_kasir");

//LAPORAN BULANAN
const LaporanSp3lb1Dinkes = () => import("@/views/dinkes/laporan_bulanan/sp3lb1/sp3lb1");
const LaporanUkp4Dinkes = () => import("@/views/dinkes/laporan_bulanan/ukp4/ukp4");
const LaporanUkp5Dinkes = () => import("@/views/dinkes/laporan_bulanan/ukp5/ukp5");
const LaporanBulananKunjunganIbuHamilDinkes = () => import("@/views/dinkes/laporan_bulanan/kunjungan_ibu_hamil/kunjungan_ibu_hamil")
const LaporanBulananKohortKiaDinkes = () => import("@/views/dinkes/laporan_bulanan/kohort_kia/kohort_kia")
const InfeksiMenularSeksualDinkes = () => import("@/views/dinkes/laporan_bulanan/infeksi_menular_seksual/infeksi_menular_seksual");
Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
    },
    {
      path: "/display_apm/:id",
      name: "Display APM",
      component: DisplayAPM,
    },
    {
      path: "/display_apm/:id",
      name: "Display APM",
      component: DisplayAPM2,
    },
    {
      path: "/display_apm/:id",
      name: "Display APM",
      component: DisplayAPM3,
    },
    {
      path: "/display_layar_loket",
      name: "Display Layar Loket",
      component: DisplayLayarLoket,
    },
    {
      path: "/display_list_poli",
      name: "List Poli Layar",
      component: DisplayListPoli,
    },
    {
      path: "/display_poli/:id",
      name: "Display Layar Poli",
      component: DisplayLayarPoli,
    },
    // baru
    {
      path: "/register_lama_umum",
      name: "Register Pasien Lama Umum",
      component: RegisterLamaUmum,
    },
    {
      path: "/register_lama_bpjs",
      name: "Register Pasien Lama BPJS",
      component: RegisterLamaBPJS,
    },
    {
      path: "/register_poli",
      name: "Register Poli",
      component: RegisterPoli,
    },
    {
      path: "/register_poli_bpjs",
      name: "Register Poli BPJS",
      component: RegisterPoliBPJS,
    },
    {
      path: "/register_poli_baru",
      name: "Register Poli Baru",
      component: RegisterPoliBaru,
    },
    {
      path: "/print_label_pasien",
      name: "Print Label Pasien",
      component: PrintLabelPasien,
    },
    // DINKES
    {
      path: "/dinkes",
      redirect: "/dashboard_dinkes",
      name: "Dinkes",
      component: TheContainerDinkes,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/dashboard_dinkes",
          name: "Dashboard Dinkes",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/sedang_mt",
          name: "Maintenance",
          component: Maintenance,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/laporan_dinkes/kunjungan_pasien",
          name: "Laporan Kunjungan Pasien Dinkes",
          component: LaporanKunjunganPasienDinkes,
        },
        {
          path: "/laporan_dinkes/penyakit",
          name: "Laporan Penyakit Dinkes",
          component: LaporanPenyakitDinkes,
        },
        {
          path: "/laporan_dinkes/kunjungan_pasien_bpjs",
          name: "Kunjungan Pasien BPJS Dinkes",
          component: LaporanKunjunganPasienBPJSDinkes,
        },
        {
          path: "/laporan_dinkes/pemeriksaan_medis",
          name: "Laporan Pemeriksaan Medis Dinkes",
          component: LaporanPemeriksaanMedisDinkes,
        },
        {
          path: "/laporan_dinkes/pelayanan_pasien",
          name: "Laporan Pelayanan Pasien Dinkes",
          component: LaporanPelayananPasienDinkes,
        },
        {
          path: "/laporan_dinkes/pelayanan_resep",
          name: "Laporan Pelayanan Resep Dinkes",
          component: LaporanPelayananResepDinkes,
        },
        {
          path: "/laporan_dinkes/pelayanan_obat",
          name: "Laporan Pengeluaran Obat Dinkes",
          component: LaporanPelayananObatDinkes
        },
        {
          path: "/laporan_dinkes/tindakan_tenaga_medis",
          name: "Laporan Tindakan Tenaga Medis Dinkes",
          component: LaporanTindakanTenagaMedisDinkes
        },
        {
          path: "/laporan_dinkes/pendapatan_tindakan",
          name: "Laporan Pendapatan Tindakan Dinkes",
          component: LaporanPendapatanTidakanDinkes
        },
        {
          path: "/laporan_dinkes/rujuk_eksternal",
          name: "Laporan Rujuk Eksternal Dinkes",
          component: LaporanRujukEksternalDinkes
        },
        {
          path: "/laporan_dinkes/rujuk_internal",
          name: "Laporan Rujuk Internal Dinkes",
          component: LaporanRujukInternalDinkes
        },
        {
          path: "/laporan_dinkes/pelayanan_ruang",
          name: "Laporan Pelayanan Ruang Dinkes",
          component: PelayananRuangDinkes
        },
        {
          path: "/laporan_dinkes/kinerja_puskesmas",
          name: "Laporan Kinerja Puskesmas Dinkes",
          component: KinerjaPuskesmasDinkes
        },
        {
          path: "/laporan_dinkes/pemeriksaan_lab",
          name: "Laporan Pemeriksaan Lab Dinkes",
          component: LaporanPemeriksaanLabDinkes
        },
        {
          path: "/laporan_dinkes/pkpr",
          name: "Laporan PKPR Dinkes",
          component: LaporanPKPRDinkes
        },
        {
          path: "/laporan_dinkes/kunjungan_ptm",
          name: "Laporan Kunjungan PTM Dinkes",
          component: LaporanKunjunganPTMDinkes
        },
        {
          path: "/laporan_dinkes/pendapatan_kasir",
          name: "Laporan Pendapatan Kasir Dinkes",
          component: LaporanPendapatanKasirDinkes
        },


        {
          path: "/laporan_dinkes_bulanan/sp3lb1",
          name: "Laporan Bulanan SP3LB1 Dinkes",
          component: LaporanSp3lb1Dinkes,
        },
        {
          path: "/laporan_dinkes_bulanan/ukp4",
          name: "Laporan Bulanan UKP 4 Dinkes",
          component: LaporanUkp4Dinkes,
        },
        {
          path: "/laporan_dinkes_bulanan/ukp5",
          name: "Laporan Bulanan UKP 5 Dinkes",
          component: LaporanUkp5Dinkes,
        },
        {
          path: "/laporan_dinkes_bulanan/kunjungan_ibu_hamil",
          name: "Kunjungan Ibu Hamil Dinkes",
          component: LaporanBulananKunjunganIbuHamilDinkes,
        },
        {
          path: "/laporan_dinkes_bulanan/kohort_kia",
          name: "Kohort KIA Dinkes",
          component: LaporanBulananKohortKiaDinkes,
        },
        {
          path: "/laporan_dinkes_bulanan/infeksi_menular_seksual",
          name: "Infeksi Menular Seksual Dinkes",
          component: InfeksiMenularSeksualDinkes,
        },
      ],
    },

    // PUSKESMAS
    {
      path: "/admin",
      redirect: "/selamat_datang",
      name: "Admin",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/test",
          name: "TEST",
          component: () => import("@/views/_test/_test"),
        },
        {
          path: "/selamat_datang",
          name: "Selamat Datang",
          component: SelamatDatang,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/change_password",
          name: "Change Password",
          component: ChangePasssword,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/icare",
          name: "I-Care",
          component: Icare,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/dashboard-puskesmas",
          name: "Dashboard Puskesmas",
          component: DashboardPuskesmas,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/bed/ms_kamar",
          name: "Master Kamar",
          component: MS_KAMAR,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/bed/ms_ruang",
          name: "Master Ruang",
          component: MS_RUANG,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/bed/ms_bed",
          name: "Master Bed",
          component: MS_BED,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/bed/monitoring",
          name: "Monitoring",
          component: Monitoring,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/bed/history_bed",
          name: "History Bed",
          component: HistoryBed,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/antrian/antrian_loket",
          name: "Antrian Loket",
          component: AntrianLoket,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/antrian/antrian_poli",
          name: "Antrian Poli",
          component: AntrianPoli,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/antrian/antrian_farmasi",
          name: "Antrian Farmasi",
          component: AntrianFarmasi,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/master",
      redirect: "/dashboard",
      name: "Master",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/master/asuransi",
          name: "Asuransi",
          component: Asuransi,
        },
        {
          path: "/master/diagnosa_gizi",
          name: "Diagnosa Gizi",
          component: DiagnosaGizi,
        },
        {
          path: "/master/imunisasi",
          name: "Imunisasi",
          component: Imunisasi,
        },
        {
          path: "/master/diet",
          name: "Diet",
          component: Diet,
        },
        {
          path: "/master/etnis",
          name: "Etnis",
          component: Etnis,
        },
        {
          path: "/master/kelas",
          name: "Kelas",
          component: Kelas,
        },
        {
          path: "/master/golongan_darah",
          name: "Golongan Darah",
          component: GolonganDarah,
        },
        {
          path: "/master/jenis_layanan",
          name: "Jenis Layanan",
          component: JenisLayanan,
        },
        {
          path: "/master/layanan",
          name: "Layanan",
          component: Layanan,
        },
        {
          path: "/master/golongan_barang",
          name: "Golongan Barang",
          component: GolonganBarang,
        },
        {
          path: "/master/jenis_barang",
          name: "Jenis Barang",
          component: JenisBarang,
        },
        {
          path: "/master/kelas_terapi",
          name: "Kelas Terapi",
          component: KelasTerapi,
        },
        {
          path: "/master/kelompok_pangan",
          name: "Kelompok Pangan",
          component: KelompokPangan,
        },
        {
          path: "/master/tipe_pangan",
          name: "Tipe Pangan",
          component: TipePangan,
        },
        {
          path: "/master/produsen",
          name: "Produsen",
          component: Produsen,
        },
        {
          path: "/master/satuan_barang",
          name: "Satuan Barang",
          component: SatuanBarang,
        },
        {
          path: "/master/komposisi_barang",
          name: "Komposisi Barang",
          component: KomposisiBarang,
        },
        {
          path: "/master/barang",
          name: "Barang",
          component: Barang,
        },
        {
          path: "/master/pendidikan",
          name: "Pendidikan",
          component: Pendidikan,
        },
        {
          path: "/master/kualifikasi",
          name: "Kualifikasi",
          component: Kualifikasi,
        },
        {
          path: "/master/loket",
          name: "Loket",
          component: Loket,
        },
        {
          path: "/master/poli",
          name: "Poli",
          component: Poli,
        },
        {
          path: "/master/jadwal",
          name: "Jadwal",
          component: Jadwal,
        },
        {
          path: "/master/specialist",
          name: "Specialist",
          component: Specialist,
        },
        {
          path: "/master/status_pulang",
          name: "Status Pulang",
          component: StatusPulang,
        },
        {
          path: "/master/jenis_tenaga_medis",
          name: "Jenis Tenaga Medis",
          component: JenisTenagaMedis,
        },
        {
          path: "/master/tenaga_medis/:user_id?",
          name: "Tenaga Medis",
          component: TenagaMedis,
        },
        {
          path: "/master/puskesmas",
          name: "Puskesmas",
          component: Puskesmas,
        },
        {
          path: "/master/user",
          name: "User",
          component: User,
        },
        {
          path: "/master/jenis_alergi",
          name: "Jenis Alergi",
          component: JenisAlergi,
        },
        {
          path: "/master/alergi",
          name: "Alergi",
          component: Alergi,
        },
        {
          path: "/master/gudang",
          name: "Gudang",
          component: Gudang,
        },
        {
          path: "/master/komponen_tarif",
          name: "Komponen Tarif",
          component: KomponenTarif,
        },
        {
          path: "/master/mcu",
          name: "MCU",
          component: MCU,
        },
        {
          path: "/master/pangan",
          name: "Pangan",
          component: Pangan,
        },
        {
          path: "/master/sumber_dana",
          name: "Sumber Dana",
          component: SumberDana,
        },
        {
          path: "/master/tindakan",
          name: "Tindakan",
          component: Tindakan,
        },
        {
          path: "/master/diagnosa",
          name: "MsDiagnosa",
          component: ListDiagnosa,
        },
        {
          path: "/master/supplier",
          name: "Supplier",
          component: Supplier,
        },
      ],
    },
    {
      path: "/pasien",
      redirect: "/dashboard",
      name: "Pasien",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/pasien/rekam_medis",
          name: "Rekam Medis",
          component: RekamMedis,
        },
        {
          path: "/pasien/detail_rekam_medis/:id",
          name: "Detail Rekam Medis",
          component: DetailRekamMedis,
        },
        {
          path: "/pasien/detail_kunjungan_by_rekam_medis/:id",
          name: "Detail Kunjungan By Rekam Medis",
          component: DetailKunjunganByRekamMedis,
        },
        {
          path: "/pasien/pasien_meninggal",
          name: "Pasien Meninggal",
          component: PasienMeninggal,
        },
        {
          path: "/pasien/input_penyebab_kematian/:kunjungan_id?",
          name: "Input Penyebab Kematian",
          component: InputPenyebabKematian,
        },
        {
          path: "/pasien/input_laporan_kematian",
          name: "Input Laporan Kematian",
          component: InputLaporanKematian,
        },
        {
          path: "/pasien/kegiatan_kelompok",
          name: "Kegiatan Kelompok",
          component: KegiatanKelompok,
        },
      ],
    },
    {
      path: "/registrasi",
      redirect: "/dashboard",
      name: "Registrasi",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/registrasi/pendaftaran",
          name: "Pendaftaran",
          component: Pendaftaran,
        },
        {
          path: "/registrasi/penimbangan_balita",
          name: "Penimbangan Balita",
          component: PenimbanganBalita,
        },
        {
          path: "/registrasi/kunjungan",
          name: "Kunjungan",
          component: Kunjungan,
        },
        {
          path: "/registrasi/diagnosa",
          name: "Diagnosa",
          component: Diagnosa,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/registrasi/rujuk_eksternal/:id",
          name: "Rujuk Eksternal",
          component: RujukEksternal,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/registrasi/rawat_inap",
          name: "Rawat Inap",
          component: RawatInap,
          meta: {
            requiresAuth: true,
          },
        },
        // {
        //   path: "/registrasi/rawat_inap/:id",
        //   name: "Rawat Inap",
        //   component: RawatInap,
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        {
          path: "/registrasi/rawat_inap_1",
          name: "Rawat Inap",
          component: RawatInap1,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/kasir",
      redirect: "/dashboard",
      name: "Kasir",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/kasir/pembayaran",
          name: "Pembayaran",
          component: Pembayaran,
          meta: {
            requiresAuth: true,
          },
        },

        // {
        //   path: "/registrasi/detail_pembayaran",
        //   name: "Detail _pembayaran",
        //   component: DetailPembayaran,
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
      ],
    },
    {
      path: "/gudang",
      redirect: "/dashboard",
      name: "Supply",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/gudang/stock/:idPuskesmas",
          name: "Stock",
          component: Stock,
        },
        {
          path: "/gudang/stock_by_gudang/:idPuskesmas/:idGudang",
          name: "Stock By Gudang",
          component: StockByGudanag,
        },
        {
          path: "/gudang/stock_by_gudang_per_barang/:idPuskesmas/:idGudang/:idBarang",
          name: "Stock By Gudang Per Barang",
          component: StockByGudangPerBarang,
        },
        {
          path: "/gudang/stock_by_gudang_detail/:idPuskesmas/:idGudang",
          name: "Stock By Gudang Detail",
          component: StockByGudangDetail,
        },
        {
          path: "/gudang/pembelian",
          name: "Pembelian",
          component: Pembelian,
        },
        {
          path: "/gudang/mutasi",
          name: "Mutasi",
          component: Mutasi,
        },
        {
          path: "/gudang/stock_opname",
          name: "Stock Opname",
          component: StockOpname,
        },
        {
          path: "/gudang/stock_opname/detail_stock_opname",
          name: "Detail Stock Opname",
          component: DetailStockOpname,
        },
        {
          path: "/gudang/stock_opname/addDetail_stock_opname",
          name: "Add Detail",
          component: AddDetailStockOpname,
        },
        {
          path: "/gudang/adjutsment",
          name: "Adjutsment",
          component: Adjutsment,
        },
      ],
    },
    {
      path: "/apotek",
      redirect: "/dashboard",
      name: "Apotek",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/apotek/resep",
          name: "Resep",
          component: Resep,
        },
        {
          path: "/apotek/detail_resep/:id",
          name: "Detail Resep",
          component: DetailResep,
        },
      ],
    },
    {
      path: "/laboratorium",
      redirect: "/dashboard",
      name: "Laboratorium",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/laboratorium/sampel_lab",
          name: "Sampel Lab",
          component: SampelLab,
        },
        {
          path: "/laboratorium/satuan_lab",
          name: "Satuan Lab",
          component: SatuanLab,
        },
        {
          path: "/laboratorium/tindakan_lab",
          name: "Tindakan Lab",
          component: TindakanLab,
        },
        {
          path: "/laboratorium/input_tindakan_lab",
          name: "Input Tindakan Lab",
          component: InputTindakanLab,
        },
        {
          path: "/laboratorium/edit_tindakan_lab",
          name: "Edit Tindakan Lab",
          component: EditTindakanLab,
        },
        {
          path: "/laboratorium/paket_lab",
          name: "Paket Lab",
          component: PaketLab,
        },
        {
          path: "/laboratorium/edit_paket_lab",
          name: "Edit Paket Lab",
          component: EditPaketLab,
        },
        {
          path: "/laboratorium/input_paket_lab",
          name: "Input Paket Lab",
          component: InputPaketLab,
        },
        {
          path: "/laboratorium/nilai_rujukan_lab",
          name: "Nilai Rujukan Lab",
          component: NilaiRujukanLab,
        },
        {
          path: "/laboratorium/input_nilai_rujukan_lab",
          name: "Input Nilai Rujukan Lab",
          component: InputNilaiRujukanLab,
        },
        {
          path: "/laboratorium/edit_nilai_rujukan_lab",
          name: "Edit Nilai Rujukan Lab",
          component: EditNilaiRujukanLab,
        },
        {
          path: "/laboratorium/detail_nilai_rujukan_lab",
          name: "Detail Nilai Rujukan Lab",
          component: DetailNilaiRujukanLab,
        },
        {
          path: "/laboratorium/pemeriksaan_lab",
          name: "Pemeriksaan Lab",
          component: PemeriksaanLab,
        },
        {
          path: "/laboratorium/input_pemeriksaan_lab",
          name: "Input Pemeriksaan Lab",
          component: InputPemeriksaanLab,
        },
        {
          path: "/laboratorium/detail_pemeriksaan_lab",
          name: "Detail Pemeriksaan Lab",
          component: DetailPemeriksaanLab,
        },
        // {
        //   path: "/laboratorium/rawat_jalan",
        //   name: "Rawat Jalan",
        //   component: RawatJalan
        // },
        // {
        //   path: "/laboratorium/kunjungan",
        //   name: "Kunjungan",
        //   component: Kunjungan
        // },
        // {
        //   path: "/laboratorium/diagnosa",
        //   name: "Diagnosa",
        //   component: Diagnosa,
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
      ],
    },
    {
      path: "/laporan",
      redirect: "/dashboard",
      name: "Laporan",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/laporan/kunjungan_pasien",
          name: "Laporan Kunjungan Pasien",
          component: LaporanKunjunganPasien,
        },
        {
          path: "/laporan/penyakit",
          name: "Laporan Penyakit",
          component: LaporanPenyakit,
        },
        {
          path: "/laporan/kunjungan_pasien_bpjs",
          name: "Kunjungan Pasien BPJS",
          component: LaporanKunjunganPasienBPJS,
        },
        {
          path: "/laporan/pemeriksaan_medis",
          name: "Laporan Pemeriksaan Medis",
          component: LaporanPemeriksaanMedis,
        },
        {
          path: "/laporan/pelayanan_pasien",
          name: "Laporan Pelayanan Pasien",
          component: LaporanPelayananPasien,
        },
        {
          path: "/laporan/pelayanan_resep",
          name: "Laporan Pelayanan Resep",
          component: LaporanPelayananResep,
        },
        {
          path: "/laporan/pelayanan_obat",
          name: "Laporan Pengeluaran Obat",
          component: LaporanPelayananObat
        },
        {
          path: "/laporan/tindakan_tenaga_medis",
          name: "Laporan Tindakan Tenaga Medis",
          component: LaporanTindakanTenagaMedis
        },
        {
          path: "/laporan/pendapatan_tindakan",
          name: "Laporan Pendapatan Tindakan",
          component: LaporanPendapatanTidakan
        },
        {
          path: "/laporan/rujuk_eksternal",
          name: "Laporan Rujuk Eksternal",
          component: LaporanRujukEksternal
        },
        {
          path: "/laporan/rujuk_internal",
          name: "Laporan Rujuk Internal",
          component: LaporanRujukInternal
        },
        {
          path: "/laporan/pelayanan_ruang",
          name: "Laporan Pelayanan Ruang",
          component: PelayananRuang
        },
        {
          path: "/laporan/kinerja_puskesmas",
          name: "Laporan Kinerja Puskesmas",
          component: KinerjaPuskesmas
        },
        {
          path: "/laporan/pemeriksaan_lab",
          name: "Laporan Pemeriksaan Lab",
          component: LaporanPemeriksaanLab
        },
        {
          path: "/laporan/pkpr",
          name: "Laporan PKPR",
          component: LaporanPKPR
        },
        {
          path: "/laporan/kunjungan_ptm",
          name: "Laporan Kunjungan PTM",
          component: LaporanKunjunganPTM
        },
        {
          path: "/laporan/pendapatan_kasir",
          name: "Laporan Pendapatan Kasir",
          component: LaporanPendapatanKasir
        },
      ]
    },

    {
      path: "/laporan_bulanan",
      redirect: "/dashboard",
      name: "Laporan Bulanan",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/laporan_bulanan/sp3lb1",
          name: "Laporan Bulanan SP3LB1",
          component: LaporanSp3lb1,
        },
        {
          path: "/laporan_bulanan/ukp4",
          name: "Laporan Bulanan UKP 4",
          component: LaporanUkp4,
        },
        {
          path: "/laporan_bulanan/ukp5",
          name: "Laporan Bulanan UKP 5",
          component: LaporanUkp5,
        },
        {
          path: "/laporan_bulanan/kunjungan_ibu_hamil",
          name: "Kunjungan Ibu Hamil",
          component: LaporanBulananKunjunganIbuHamil,
        },
        {
          path: "/laporan_bulanan/kohort_kia",
          name: "Kohort KIA",
          component: LaporanBulananKohortKia,
        },
        {
          path: "/laporan_bulanan/infeksi_menular_seksual",
          name: "Infeksi Menular Seksual",
          component: InfeksiMenularSeksual,
        },
        {
          path: "/laporan_bulanan/konseling_hiv",
          name: "Konseling Hiv",
          component: LaporanKonselingHiv,
        },
        {
          path: "/laporan_bulanan/rekapitulasi_mtbs_mtbm",
          name: "Rekapitulasi MTBS MTBM",
          component: RekapitulasiMtbsMtbm,
        },
      ],
    },
    {
      path: "/cms",
      redirect: "/dashboard",
      name: "Content Management",
      component: TheContainer,
      children: [
        {
          path: "/cms/ms_akses",
          name: "Ms Akses",
          component: MsAkses,
        },
        {
          path: "/cms/role_akses",
          name: "Role Akses",
          component: RoleAkses,
        },
        {
          path: "/cms/ms_role",
          name: "Ms Role",
          component: MsRole,
        },
      ],
    },
    {
      path: "/grafik",
      redirect: "/dashboard",
      name: "Grafik",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/grafik/pemeriksaan_tenaga_medis",
          name: "Pemeriksaan Tenaga Medis",
          component: PemeriksaanTenagaMedis,
        },
        {
          path: "/grafik/pendapatan_harian",
          name: "Pendapatan Harian",
          component: PendapatanHarian,
        },
        {
          path: "/grafik/statistik_pasien",
          name: "Statistik Pasien",
          component: StatistikPasien,
        },
        {
          path: "/grafik/tindakan",
          name: "Grafik Tindakan",
          component: GrafikTindakan,
        },
        {
          path: "/grafik/transaksi",
          name: "Grafik Transaksi",
          component: Transaksi,
        },
        {
          path: "/grafik/pembayaran",
          name: "Grafik Pembayaran",
          component: GrafikPembayaran,
        },
        {
          path: "/grafik/penyakit",
          name: "Grafik Penyakit",
          component: Penyakit,
        },
        {
          path: "/grafik/stock_opname",
          name: "Grafik Stock Opname",
          component: GrafikStockOpname,
        },
        {
          path: "/grafik/statistik_kunjungan",
          name: "Statistik Kunjungan",
          component: StatistikKunjungan,
        },
        {
          path: "/grafik/statistik_pembelian",
          name: "Statistik Pembelian",
          component: StatistikPembelian,
        },
        {
          path: "/grafik/statistik_prolanis",
          name: "Statistik Prolanis",
          component: StatistikProlanis,
        },
        {
          path: "/grafik/kunjungan",
          name: "Grafik Kunjungan",
          component: GrafikKunjungan,
        },
      ],
    },
  ],
});

router.beforeEach(async function (to, from, next) {
  let token = stores.state.user_token ? stores.state.user_token : null;
  let urls = stores.state.all_url ? stores.state.all_url : [];
  // let acc = stores.state.all_akses ? stores.state.all_akses : [];
  // let token = this.$store.state.rs_token;
  // console.log(urls, 'ini token');
  if (to.path == "/logout") {
    stores.commit("clear_data");
    next("/");
  } else if (!to.meta.requiresAuth) {
    next();
  } else {
    if (!token) {
      // console.log("iki1");
      if (to.path != "/") {
        stores.commit("set_alert", {
          variant: "warning",
          msg: "Silahkan login terlebih dahulu.",
        });
        next("/");
      } else {
        if (urls.includes(to.path)) {
          next();
        }
      }
    } else {
      setToken(token);
      next();
    }
  }
});

export default router;
