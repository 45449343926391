import { io } from "socket.io-client";
// import store from "@/store/store";
import { ipBackend } from "../ipBackend";

const socketss = io(ipBackend, {
  transports: ["websocket"]
  // extraHeaders: {
  //   token : store.state.login_token
  // }
});
// class SocketioService {
//   socket;
//   constructor() {}

//   setupSocketConnection() {
//     this.socket = io(process.env.VUE_APP_SOCKET_ENDPOINT);
//     this.socket.on("refresh", (data) => {
//       console.log(data, "wadefak mannnnn");
//     });
//   }
//   disconnect() {
//     if (this.socket) {
//       this.socket.disconnect();
//     }
//   }
//   panggil(asd) {
//     this.socket.emit("panggil", asd);
//   }
//   // refresh() {
//   //   this.socket.on("refresh", (data) => {
//   //     console.log(data, "uhuy");
//   //   });
//   // }
// }

export default socketss;
