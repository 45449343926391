import axios from "axios";

export function setPreToken(token) {
  // axios.defaults.headers.common["pre_token"] = token;
  axios.defaults.headers.common["token"] = token;
}

export function setToken(token) {
  axios.defaults.headers.common["token"] = token;
}

export function removeToken() {
  delete axios.defaults.headers.common["pre_token"];
  delete axios.defaults.headers.common["token"];
}
