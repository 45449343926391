export default function (array, key, o, v, t) {
  // array = [] list / sumber
  // key   = "" value
  // o     = true / false return original
  // v     = "" key dari list
  // t     = "" key untuk return jika bukan original
  const value = v || 'value' 
  const text = t || 'text' 
  const obj = o
  // console.log('key', key)
  if(array){
    for (let i = 0; i < array.length; i++) {
      const x = array[i];
      // console.log('value', x[value], key)
      if(x[value] == key){
        // console.log('text', t)
        return obj ? {...x, key: i} : x[text]
      }
    }
  }
  return null
}