import 'core-js/stable'
import Vue from 'vue'
//import CoreuiVuePro from '@coreui/vue-pro'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import store from './store/store.js'
import i18n from './i18n.js'
import socketss from "./services/socketio.service";
import "leaflet/dist/leaflet.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/id';
import "vue2-datepicker/index.css";
import VueHtmlToPaper from 'vue-html-to-paper';
import VueDrawer from 'vue-drawer-component'

import ip from "@/axios/index.js";
import moment from "moment";
import "moment/locale/id";
import numberFormat from "./functions/numberFormat.js";
import findKey from "./functions/findKey.js";
import romanize from './functions/romanize.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'



//FONTAWESOME
library.add(faHatWizard)

//PRINT
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    // 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    // '/style-print.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
moment.locale("id")

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).locale("id").format("dddd, DD/MM/YYYY");
  }
});

Vue.component("Multiselect", Multiselect);
Vue.component("DatePicker", DatePicker);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(CoreuiVuePro);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CoreuiVuePro)
Vue.use(VueHtmlToPaper, options);

Vue.prototype.$numberFormat = numberFormat;
Vue.prototype.$findKey = findKey;
Vue.prototype.$moment = moment;
Vue.prototype.$axios = ip;
Vue.prototype.$log = console.log.bind(console);
Vue.prototype.$socket = socketss;
Vue.prototype.$romanize = romanize;

Vue.component(VueDrawer.name, VueDrawer)
import createHelper from 'vue-router-keep-alive-helper'
createHelper({Vue,router, replaceStay:["/master/asuransi","/cart","/mine"]})
new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
  
})
